.scrollhost::-webkit-scrollbar {
  display: none;
  display: block;
  width: 2px;
}
.scrollhost::-webkit-scrollbar-button {
  display: none;
}

.scrollhost::-webkit-scrollbar-track {
  background-color: #00000000;
}

.scrollhost::-webkit-scrollbar-track-piece {
  background-color: #00000000;
}

.scrollhost::-webkit-scrollbar-thumb {
  background-color: #00000040;
  border: 1px solid #ffffff40;
  border-radius: 24px;
}
.scrollhost{
  overflow: overlay
}
.MuiListItemText-primary {
  font-weight: bold;
}